#footer {
  margin-top: 20px;
  background-color: var(--secondary-color) !important;
  color: var(--reverse-color);

  .footerMain {
    .footer {
      padding: 3rem 2rem;
      background-color: var(--secondary-color) !important;
      font-size: 1.1rem;
      font-weight: bold;
      .item {
        color: var(--reverse-color) !important;
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }

  .signature {
    max-width: 100%;
    padding: 1rem 1rem;
    //background-color: black !important;
    background-color: var(--secondary-color) !important;
    background-color: rgba(0, 0, 0, 0.374) !important;

    .item {
      color: var(--reverse-color);
    }
  }
}
